import PropTypes from 'prop-types'
import { Navigate, useParams } from 'react-router-dom';

const Redirect = ({path}) => {
    const params = useParams()
    if (path) {
        return <Navigate to={`${path}${params.id}`} replace />;
    }
}

Redirect.propTypes = {
    path: PropTypes.string.isRequired
}
export default Redirect