
import { extendTheme } from '@chakra-ui/react'
import { inputTheme } from '@components/theme/Input.jsx'
import { modalTheme } from '@components/theme/modal.jsx'
import { menuTheme } from '@components/theme/menu.jsx'


const Theme = extendTheme({
      styles: {
         global: (props) => ({
            'html, body': {
               color: props.colorMode === 'dark' ? 'gray.200' : 'gray.700',
               bg: props.colorMode === 'dark' ? 'rgb(28, 32, 57)' : 'purple.50',
               lineHeight: 'tall'
            }
         })
     },
     config: { initialColorMode: 'dark', useSystemColorMode: false},
     fonts: {
        heading: `'Heading Font Name', MYRIADPRO-BOLD`,
        body: `'Body Font Name', MYRIADPRO-REGULAR`
     },
     components: {
      Modal: modalTheme,
      Form: {
         baseStyle: {
            fontStyle:"bold"
         }
      },
      Menu: menuTheme,
      Button: {
         baseStyle: {
            borderRadius: 'none',
            fontWeight: 'bold',
            color: "grey.700"
         }
      },
      Input: {
         ...inputTheme,
         sizes: {
            xs: {
               field: {
               borderRadius: 'none'
               }
            },
            sm: {
               field: {
               borderRadius: 'none'
               }
            },
            md: {
               field: {
               borderRadius: 'none'
               }
            },
            lg: {
               field: {
               borderRadius: 'none'
               }
            }
        }
      }
    }
})

export default Theme