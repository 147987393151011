// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

// ** Route Components
import PublicRoute from '@components/routes/PublicRoute'

const Dashboard = lazy(() => import('@features/user/UserDashboard'))
const Home = lazy(() => import('@features/home/Home'))
const Login = lazy(() => import('@features/auth/Login'))
const AppLayout = lazy(() => import('@components/layout/AppLayout'))
const Error = lazy(() => import('@components/error/Error'))
const Brand = lazy(() => import('@features/membership/Brand'))
const MembershipCart = lazy(() => import('@features/membership/MembershipCart'))
const CustomMembershipCart = lazy(() => import('@features/membership/CustomMembershipCart'))
const LeaseMembershipCart = lazy(() => import('@features/membership/LeaseMembershipCart'))
const CheckoutResult = lazy(() => import('@features/membership/CheckoutResult'))
const CheckoutSuccess = lazy(() => import('@features/membership/CheckoutSuccess'))
const CheckoutFailure = lazy(() => import('@features/membership/CheckoutFailure'))
const Terms = lazy(() => import('@features/Terms'))
const Faqs = lazy(() => import('@features/Faqs'))
const Refunds = lazy(() => import('@features/Refunds'))
const Privacy = lazy(() => import('@features/Privacy'))
const About = lazy(() => import('@features/About'))
const ScanQrCode = lazy(() => import('@features/membership/ScanQrCode'))
import Redirect from '@components/routes/Redirect'

// ** Document title
const TemplateTitle =
  '%s - What is Zeno - Zeno powers premium brands to sell and manage digital memberships and collectibles.'

// ** Default Route
const DefaultRoute = '/'

// ** Merge Routes
const Routes = [
  {
    path: '/home',
    index: true,
    element: <Navigate replace to={DefaultRoute} />
  },
  {
    path: '/',
    element: <AppLayout><PublicRoute><Home /></PublicRoute></AppLayout>
  },
  {
    path: '/profile',
    element: <AppLayout><PublicRoute route={{meta: { restricted: true}}}><Dashboard /></PublicRoute></AppLayout>
  },
  {
    path: '/error',
    element: <AppLayout><PublicRoute route={{meta: { restricted: true}}}><Error /></PublicRoute></AppLayout>
  },
  {
    path: '/login',
    element: <AppLayout><Login/></AppLayout>
  },
  {
    path: "/brand/:slug",
    element: <AppLayout><PublicRoute><Brand/></PublicRoute></AppLayout> 
  },
  {
    path: "/collection/:id",
    element: <AppLayout><PublicRoute><MembershipCart/></PublicRoute></AppLayout>
  },
  {
    path: "/collections/:id",
    element: <AppLayout><PublicRoute><MembershipCart/></PublicRoute></AppLayout>
  },
  {
    path: "/airdrop/:id",
    element: <AppLayout><PublicRoute><CustomMembershipCart/></PublicRoute></AppLayout>
  },
  {
    path: "/airdrop/:id",
    element: <AppLayout><PublicRoute><CustomMembershipCart/></PublicRoute></AppLayout>
  },
  {
    path: "/scan",
    element: <AppLayout><PublicRoute><ScanQrCode/></PublicRoute></AppLayout>
  },
  {
    path: "/collectible/:id",
    element: <AppLayout><PublicRoute><LeaseMembershipCart/></PublicRoute></AppLayout>
  },
  {
    path: "/collectible/:id/lease",
    element: <AppLayout><PublicRoute><Redirect path="/collectible/" /></PublicRoute></AppLayout>
  },
  {
    path: "/payment",
    element: <AppLayout><PublicRoute><CheckoutResult/></PublicRoute></AppLayout>
  },
  {
    path: "/checkout/:id/success",
    element: <AppLayout><PublicRoute><CheckoutSuccess/></PublicRoute></AppLayout>
  },
  {
    path: "/checkout/:id/failure",
    element: <AppLayout><PublicRoute><CheckoutFailure/></PublicRoute></AppLayout>
  },
  {
    path: "/faq",
    element: <AppLayout><PublicRoute><Faqs/></PublicRoute></AppLayout>
  },
  {
    path: "/terms",
    element: <AppLayout><PublicRoute><Terms/></PublicRoute></AppLayout>
  },
  {
    path: "/privacy",
    element: <AppLayout><PublicRoute><Privacy/></PublicRoute></AppLayout>
  },
  {
    path: "/refunds",
    element: <AppLayout><PublicRoute><Refunds/></PublicRoute></AppLayout>
  },
  {
    path: "/about",
    element: <AppLayout><PublicRoute><About/></PublicRoute></AppLayout>
  }

]

export { DefaultRoute, TemplateTitle, Routes }
