// ** React Imports
import { Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// ** Utils
import {  getHomeRouteForLoggedInUser } from '@utils/Utils'
import PropTypes from 'prop-types'
const PublicRoute = ({ children, route }) => {
  const { userData } = useSelector(state => state.auth)
  if (route) {
    const restrictedRoute = route.meta && route.meta.restricted
    if (userData && !userData.account_id && restrictedRoute) {
      return <Navigate to={getHomeRouteForLoggedInUser(userData.role)} />
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

PublicRoute.propTypes = {
  children: PropTypes.node,
  route: PropTypes.object
}
export default PublicRoute
