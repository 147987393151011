// ** Axios Imports
import * as url from '@utility/URLHelper'
import { post, put, get } from '@utility/APIHelper'

export async function getUser(params) {
  const response = await post(url.GET_USER, params)
  return response
}

export async function checkToken(params) {
  const response = await post(url.CHECK_TOKEN, params)
  return response
}

export async function getRefreshToken(params) {
  const response = await put(url.GET_USER, params)
  return response
}

export async function setIdentity(params) {
  const response = await put(url.GET_ACCOUNT, params)
  return response
}

export async function sendOTPService(params) {
  const response = await post(url.SEND_OTP, params)
  return response
}

export async function verifyOTPService(params) {
  const response = await post(url.VERIFY_OTP, params)
  return response
}

export async function findAccount(params) {
  const response = await get(url.GET_ACCOUNTS_BY_PHONE, params)
  return response
}