import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    _light: {
      border: '1px solid',
      borderColor: 'purple.100',
      background: 'purple.50',
      _hover: {
        border: '2px solid',
        borderColor: 'purple.100'
      },
      color: 'gray.600'
    },
    _dark: {
      border: '1px solid',
      borderColor: 'gray.600',
      background: 'rgb(28, 32, 57)',
      _hover: {
        border: '2px solid',
        borderColor: 'gray.400'
      },
      color: 'gray.400'
    }
  }
})

export const inputTheme = defineMultiStyleConfig({ baseStyle })