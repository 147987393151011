// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

import { getUser } from '../utility/UserHelper'
const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX

const initialUser = () => {
  return getUser()
}

const initialAuthToken = () => {
  const item = localStorage.getItem(`${cookiePrefix}authToken`)
  return item ? item : null
}

const isLoggedIn = () => {
  const user = initialUser()
  const exp = Object.keys(user).length !== 0
  return exp
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userData: initialUser(),
    authToken: initialAuthToken(),
    isLoggedIn: isLoggedIn()
  },
  reducers: {
    handleLogin: (state, action) => {
      const userData = action.payload.userData
      state.userData = {
        ...userData.account,
        phone_number: userData.identity ? userData.identity : '',
        kyc: userData.kyc ? userData.kyc : null
      }

      state.authToken = action.payload.authToken
      localStorage.setItem(`${cookiePrefix}userData`, JSON.stringify(userData))

      if (action.payload.authToken) {
        localStorage.setItem(`${cookiePrefix}authToken`, action.payload.authToken)
      }
      state.isLoggedIn = isLoggedIn()
    },
    handleLogout: state => {
      state.userData = {}
      state.authToken = null
      state.isLoggedIn = isLoggedIn()

      // ** Remove user & accessToken from localStorage
      localStorage.removeItem(`${cookiePrefix}userData`)
      localStorage.removeItem(`${cookiePrefix}authToken`)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
