export function getUser() {
  const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX

  let user = localStorage.getItem(`${cookiePrefix}userData`)

  if (user) {
    user = JSON.parse(user)
    return {
      ...user.account,
      phone_number: user.identity ? user.identity : '',
      kyc: user.kyc ? user.kyc : null
    }
  }
  return {}
}

