import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'MYRIADPRO-REGULAR'; /*Can be any text*/
        src: local('MYRIADPRO-REGULAR'),
          url('@assets/fonts/Myriad-Pro-Regular.otf') format('opentype');
      }
      
      @font-face {
        font-family: 'MYRIADPRO-BOLD'; /*Can be any text*/
        src: local('MYRIADPRO-BOLD'),
          url('@assets/fonts/Myriad-Pro-Bold.otf') format('opentype');
      }
      
      @font-face {
        font-family: 'MYRIADPRO-SEMIBOLD'; /*Can be any text*/
        src: local('MYRIADPRO-SEMIBOLD'),
          url('@assets/fonts/Myriad-Pro-Semibold.otf') format('opentype');
      }
      `}
  />
)

export default Fonts