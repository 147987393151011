import { useEffect, useState, Suspense, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import { useQuery } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'
import Router from './router/Router'
import { checkToken, getRefreshToken } from '@services/AuthenticationService'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { initFirebaseBackend } from '@utility/FirebaseHelper'
import { handleLogin, handleLogout } from '@store/auth'
import { ChakraProvider, Spinner, Center, useToast } from '@chakra-ui/react'
import  Fonts  from '@components/theme/fonts'
import Theme from '@components/theme/theme'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_APIKEY,
  authDomain: import.meta.env.VITE_APP_AUTHDOMAIN,
  databaseURL: import.meta.env.VITE_APP_DATABASEURL,
  projectId: import.meta.env.VITE_APP_PROJECTID,
  storageBucket: import.meta.env.VITE_APP_STORAGEBUCKET,
  messagingSenderId: import.meta.env.VITE_APP_MESSAGINGSENDERID,
  appId: import.meta.env.VITE_APP_APPID,
  measurementId: import.meta.env.VITE_APP_MEASUREMENTID
}

const TRACKING_ID = import.meta.env.VITE_APP_GA

const sentryConfig = {
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/zeno\.ltd\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
}

const ROUTES_WHICH_DO_NOT_NEED_LOGIN = [
  '/home',
  '/collectible',
  '/collection',
  '/airdrop',
  '/lease',
  '/brand'
]

if (import.meta.env.NODE_ENV === 'production') {
  Sentry.init(sentryConfig)
}


const App = () => {
  const [firebaseAuthSuccess, setFirebaseAuthSuccess] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userData } = useSelector(state => state.auth)
  const toast = useToast()
  const [searchParams] = useSearchParams()


  // init firebase backend
  const fireBaseBackend = initFirebaseBackend(
    firebaseConfig,
    setFirebaseAuthSuccess
  )

  const logoutUser = useCallback(error => {
    toast({
      title: 'Authentication Failed',
      description: "Error in authentication. Please login." + error,
      status: 'error',
      duration: 5000,
      isClosable: true
    })
    dispatch(handleLogout())
    navigate('/login')
  },[dispatch, navigate, toast])

  const checkAndRefreshToken =  () => {
    return new Promise((resolve, reject) => {
      checkToken()
        .then(response => {
          if (
            new Date(response.data.expiry * 1000) <
            new Date(new Date().getTime() + (10 * 24 * 60 * 60 * 1000))
          ) {
            fireBaseBackend.getIDTokenUser()
             .then(token => getRefreshToken({oauth_token: token, provider:'firebase'}))
             .then(response => {
                dispatch(
                  handleLogin({
                    userData: response.data,
                    authToken:
                    response.data &&
                    response.data.access_keys &&
                    response.data.access_keys.access_token
                        ? response.data.access_keys.access_token
                        : null
                  })
                )
                resolve(response)
             })
          } else {
            resolve(response)
          }
        })
        .catch(error => {
          reject(error.message)
        })
    })
  }

  const tokenQuery = useQuery({
    queryKey: ['token'],
    queryFn: () => checkAndRefreshToken()
      .then(response => response.data)
      .catch(error => {
        console.log(error)
        toast({
            title: 'Auth session expired',
            description: "You auth session has expired, please try to login again",
            status: 'error',
            duration: 5000,
            isClosable: true
        }) 
      }),
    enabled: !!(firebaseAuthSuccess && userData.account_id && userData.user_name)
  })

  useEffect(() => {
    if (tokenQuery.isError) {
      logoutUser(tokenQuery.error)
    }
  },[logoutUser, tokenQuery.error, tokenQuery.isError])

  useEffect(() => {
    if (!userData) {

      if (location.pathname === '/') {
        navigate(location)
      } else {
        const found = ROUTES_WHICH_DO_NOT_NEED_LOGIN.find(route =>
          location.pathname.includes(route)
        )

        if (found) {
          navigate(location)
        } else {
          navigate(`/login${location.search}`)
        }
      }

      Sentry.setUser(null)
      ReactGA.initialize(TRACKING_ID)
      ReactGA.set({ page: location.pathname })
    } else {
      Sentry.setUser({
        id: userData.account_id,
        username: userData.user_name
      })

      ReactGA.initialize([
        {
          trackingId: TRACKING_ID,
          gaOptions: {
            userId: userData.account_id
          }
        }
      ])

      ReactGA.set({ page: location.pathname, userId: userData.account_id })
    }

    // If new route is login and if user is already logged in
    if (userData?.account_id && location.pathname === '/login') {
      const search = location.search
      const collection_id = new URLSearchParams(search).get('collection_id')
      const airdrop_id = new URLSearchParams(search).get('airdrop_id')
      const collectible_id = new URLSearchParams(search).get('collectible_id')
      const redirectTo = searchParams.get('redirectTo')
      console.log(redirectTo)
      if (airdrop_id) {
        navigate(`/airdrop/${airdrop_id}`)
      } else if (collection_id) {
        navigate(`/collection/${collection_id}`)
      } else if (collectible_id) {
        navigate(`/collectible/${collectible_id}`)
      } else {
        if (redirectTo) {
          navigate(redirectTo)
        } else {
          navigate('/profile')
        }
      }
    }
  }, [location, location.pathname, navigate, searchParams, userData])

  return (
    <ChakraProvider theme={Theme}>
      <Fonts />
      {firebaseAuthSuccess ? (
      <Suspense fallback={null}>
       <Router />
      </Suspense>
      ) : (
        <Center h='50%'><Spinner size='xl' />Loading...</Center>
       )}
    </ChakraProvider>
  )
}

export default App
