import axios from 'axios'
import * as Sentry from '@sentry/react'

//apply base url for axios
const API_URL = import.meta.env.VITE_APP_APISERVER

const axiosApi = axios.create({
  baseURL: API_URL
})
const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX

axiosApi.interceptors.request.use(config => {
  const token = localStorage.getItem(`${cookiePrefix}authToken`)
  if (config.headers.Authorization === null) {
    delete config.headers.Authorization
  } else if (token) {
    config.headers.Authorization = `Bearer ${token}`
  } else {
    // config.headers.Authorization = `Bearer ${ANON_AUTH}`
  }
  return config
})

axiosApi.interceptors.response.use(
  response => {
    console.log(JSON.stringify(response)) // This is logged for sentry breadcrumbs
    return response
  },
  error => {
    Sentry.captureException(error)
    return Promise.reject(error)
  }
)

export const handleError = (error, toast) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)
    toast({ title: `${error.response.data.data.message}`, status: 'error', duration: 3000, isClosable: true })
    return error.response.data
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request)
    toast({ title: `Network error!`, status: 'error', duration: 3000, isClosable: true })
    return error.request
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message)
    toast({ title: `${error.message}`, status: 'error', duration: 3000, isClosable: true })
  }
  console.log(error.config)
}


export const get = async (url, data = {}, headers = {}) => {
  return await axiosApi
    .request({ url, headers, method: 'get', params: data })
    .then((response) => response.data)
}

export const post = async (url, data = {}, headers = {}) => {
  return await axiosApi
    .request({ url, headers, method: 'post', data })
    .then(response => response.data)
}

export const put = async (url, data = {}, headers = {}) => {
  return await axiosApi
    .request({ url, headers, method: 'put', data })
    .then(response => response.data)
}

export const del = async (url, data = {}, headers = {}) => {
  return await axiosApi
    .request({ url, headers, method: 'delete', data })
    .then(response => response.data)
}
