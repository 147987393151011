import { initializeApp } from 'firebase/app'

// Add the Firebase products that you want to use
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithCustomToken,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  signOut
} from 'firebase/auth'
//import "firebase/compat/firestore"
const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX

const handleError = (error) => {
  // var errorCode = error.code;
  const errorMessage = error.message
  return errorMessage
}

class FirebaseAuthBackend {
  constructor(firebaseConfig, setFirebaseAuthSuccess) {
    if (firebaseConfig) {
      // Initialize Firebase
      initializeApp(firebaseConfig)
      const auth = getAuth()
      onAuthStateChanged(auth, () => {
        setFirebaseAuthSuccess(true)
      })
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password, displayName) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      createUserWithEmailAndPassword(auth, email, password)
        .then(userCredential => {
          updateProfile(userCredential.user, { displayName })
          sendEmailVerification(userCredential.user)
          resolve(userCredential.user)
        },
        error => {
          reject(handleError(error))
        })
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          resolve(userCredential.user.getIdToken())
        },
        error => {
          reject(handleError(error))
        })
    })
  }

  loginUserMobile = (phoneNumber) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible'
      }, auth)
      signInWithPhoneNumber(auth, `+91${phoneNumber}`, window.recaptchaVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          resolve()
        },
        error => {
          reject(handleError(error))
        })
    })
  }

  loginWithCustomToken = (token) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      signInWithCustomToken(auth, token)
        .then((userCredential) => {
          const user = userCredential.user
          resolve(user)
        })
        .catch((error) => {
          console.log('error in loginWithCustomToken', error)
          reject(handleError(error))
        })
    })
  }

  resendOTP = (phoneNumber) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      signInWithPhoneNumber(auth, `+91${phoneNumber}`, window.recaptchaVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          resolve()
        },
        error => {
          reject(handleError(error))
        })
    })
  }

  invisibleRecaptcha = (signInButton) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      window.recaptchaVerifier = new RecaptchaVerifier(signInButton, {
        size: 'invisible',
        callback: () => {
          resolve()
        },
        'expired-callback': () => {
          console.log('Recaptcha expired')
          reject(handleError('Recaptcha expired'))
        },
        error: () => {
          console.log('Error')
        }
      }, auth)
    })
  }

  confirmCodeUserMobile = (code) => {
    return new Promise((resolve, reject) => {
      window.confirmationResult.confirm(code)
        .then((result) => {
          resolve(result.user)
        },
        error => {
          reject(handleError(error))
        })
    })
  }

  /**
   * Login user with given details
   */
  getIDTokenUser = () => {
    return new Promise((resolve) => {
      const auth = getAuth()
      resolve(auth.currentUser.getIdToken())
    })
  }

  /**
   * forgot Password user with given details
   */
  forgotPassword = email => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      sendPasswordResetEmail(auth, email, {
          url: `${window.location.protocol}//${window.location.host}/login`
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logoutUser = () => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      signOut(auth)
        .then(() => {
          resolve(true)
          localStorage.removeItem(`${cookiePrefix}authUser`)
        })
        .catch(error => {
          reject(handleError(error))
        })
    })
  }

  /*addNewUserToFirestore = user => {
    const collection = firebase.firestore().collection("users")
    const { profile } = user.additionalUserInfo
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp()
    }
    collection.doc(firebase.auth().currentUser.uid).set(details)
    return { user, details }
  }*/
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config, setFirebaseAuthSuccess) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config, setFirebaseAuthSuccess)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
