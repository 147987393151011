import { createSlice } from '@reduxjs/toolkit'

export const transactionFiltersSlice = createSlice({
  name: 'transactionFilters',
  initialState: {
    transactionFilters: []
  },
  reducers: {
    addOrUpdateFilter: (state, action) => {
      const filter = action.payload

      const index = state.transactionFilters.findIndex(
        tempFilter => tempFilter.label === filter.label
      )

      if (index < 0) {
        state.transactionFilters.push(filter)
      } else {
        state.transactionFilters.splice(index, 1)
      }

      console.log(index)
    },
    addFilters: (state, action) => {
      const filters = action.payload

      state.transactionFilters = filters
    }
  }
})

// Action creators are generated for each case reducer function
export const { addOrUpdateFilter, addFilters } = transactionFiltersSlice.actions
export default transactionFiltersSlice.reducer
